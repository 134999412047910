import React from 'react';
import Contact from '../home/Contact';
import Hero from '../home/Hero';
import Intro from '../home/Intro';
import MobileApps from '../home/MobileApps';
import Services from '../home/Services';
import Testimonials from '../home/Testimonials';
import Websites from '../home/Websites';

const componentsMap = {
	'home.contact': Contact,
	'home.hero': Hero,
	'home.intro': Intro,
	'home.mobile-apps': MobileApps,
	'home.services': Services,
	'home.testimonials': Testimonials,
	'home.websites': Websites,
}

const Block = ({ block }) => {
	const Component = componentsMap[block.type];

	if (!Component) {
		return null;
	}

	return <Component data={block.data} />

}

const HomeBlocksRenderer = ({ blocks }) => {
	return (
		<>
			{blocks.map((block, index) => {
				return (
					<Block key={index} block={block} />
				)
			})}
		</>
	)
}

export default HomeBlocksRenderer;
