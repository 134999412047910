import React from "react";
import Slider from "react-slick";
import cx from "classnames";
import * as styles from "./OurCustomers.module.scss";

const OurCustomers = ({ clients }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    infinite: true,
    pauseOnHover: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div className={cx(styles.container, "container")}>
      <Slider {...settings}>
        {clients.map((client, idx) => (
          <div key={idx}>
            <div
              className={styles.img}
              style={{ backgroundImage: `url(${client.imageUrl})` }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OurCustomers;
