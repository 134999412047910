import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionContent from "../section-content/SectionContent";
import cx from "classnames";
import * as styles from "./MobileApps.module.scss";

const MobileApps = ({ data }) => {
  console.log("Mobile Appz", data);
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        {data.imageMobile?.data ? (
          <div className="d-block d-md-none">
            <figure className={styles.figure}>
              <img
                alt={data.imageMobile.data.attributes.alternativeText}
                src={data.imageMobile.data.attributes.url}
                className="img-fluid w-100"
              />
            </figure>
          </div>
        ) : (
          ``
        )}
        <Row className="align-items-center">
          <Col
            xs={12}
            md={4}
            className={cx(
              "d-none d-md-block",
              data.displayType === "imageOnRight" && "offset-md-1 order-md-2"
            )}
          >
            {data.imageDesktop?.data ? (
              <figure className={styles.figure}>
                <img
                  alt={data.imageDesktop.data.attributes.alternativeText}
                  src={data.imageDesktop.data.attributes.url}
                  className="img-fluid"
                />
              </figure>
            ) : (
              ``
            )}
          </Col>
          <Col
            xs={12}
            md={7}
            className={cx(
              "text-start",
              data.displayType === "imageOnRight" ? "order-md-1" : "offset-md-1"
            )}
          >
            <SectionContent title={data.title} paragraph={data.paragraph} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MobileApps;
