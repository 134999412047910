import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import Hero from "../components/home/Hero";
import HomeBlocksRenderer from "../components/home-blocks-renderer/HomeBlocksRenderer";
import "../style/main.scss";

const Home = ({ pageContext: { homePage, clients } }) => {
  const metaTitle = ["Ivo Digital", homePage.seo?.title]
    .filter(Boolean)
    .join(" - ");
  const metaDescription = homePage.seo?.description || "";
  const metaImage = homePage.seo?.imageUrl || "";
  const url = typeof window !== `undefined` ? window.location.href : ``;

  return (
    <Layout logoColour="yellow">
      <Helmet htmlAttributes={{ lang: `en` }}>
        <meta charSet="utf-8" />
        <title>{metaTitle}</title>
        <link rel="canonical" href={url} />
        <link rel={`description`} content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:site_name" content={metaTitle} />
      </Helmet>
      <Hero clients={clients} data={homePage.hero.data} />
      <HomeBlocksRenderer blocks={homePage.blocks || []} />
    </Layout>
  );
};

export default Home;
