import React from "react";
// import Fade from 'react-reveal/Fade';
import PropTypes from "prop-types";
import cx from "classnames";
import * as styles from "./SectionContent.module.scss";
import ReactMarkdown from "react-markdown";

const SectionContent = ({ title, paragraph }) => (
  <div className={styles.content}>
    {/*<Fade bottom duration={1000} delay={300} distance="0px" ssrReveal>*/}
    {/*  <h2 className={`uikit-h2 uikit-mobile-36 ${styles.title}`}>{title}</h2>*/}
    {/*  <p className={`uikit-p-16 uikit-mobile-20 ${styles.paragraph}`}>{paragraph}</p>*/}
    {/*</Fade>*/}
    <h2 className={`uikit-h2 uikit-mobile-36 ${styles.title}`}>{title}</h2>
    <div className={cx("uikit-p-16 uikit-mobile-20", styles.paragraph)}>
      <ReactMarkdown>{paragraph}</ReactMarkdown>
    </div>
  </div>
);

SectionContent.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default SectionContent;
