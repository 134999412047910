import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import * as styles from './Testimonials.module.scss';
import quoteIcon from '../../images/quoteIcon.png';

const Testimonials = ({ data }) => {

	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: false,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
	};
	return (
		<section className={`uikit-bg-dark ${styles.section}`}>
			<Container>
				<figure className="mb-0 d-flex justify-content-center">
					<img className="img-fluid" src={quoteIcon} alt="Quote icon" width={90} height={90} />
				</figure>
				<Slider {...settings}>
					{data.testimonials.map((testimonial, index) => (
						<div className={styles.slide} key={index}>
							<h3 className={styles.testimonial}>{testimonial.quote}</h3>
							{testimonial.image?.data ? (
								<figure className="mb-0">
									<img
										alt={testimonial.image.data.attributes.alternativeText}
										src={testimonial.image.data.attributes.url}
										className="img-fluid"
										width={160}
									/>
								</figure>
							) : ``}
							<p className={`uikit-p-20 uikit-bold ${styles.source}`}>{testimonial.citation}</p>
						</div>
					))}
				</Slider>
			</Container>
		</section>
	);
};

export default Testimonials;
