import React from "react";
import { Container, Row } from "react-bootstrap";
import SectionContent from "../section-content/SectionContent";
import cx from "classnames";
import * as styles from "./Websites.module.scss";

const Websites = ({ data }) => {
  return (
    <section className={styles.section}>
      {data.imageMobile?.data ? (
        <figure className="mb-0 d-block d-md-none">
          <img
            alt={data.imageMobile.data.attributes.alternativeText}
            src={data.imageMobile.data.attributes.url}
            className="img-fluid"
          />
        </figure>
      ) : (
        ``
      )}
      <Container>
        <Row>
          <div
            className={cx(
              "col-12 col-md-6 mt-5 mt-md-0",
              data.displayType === "imageOnLeft" && "offset-md-6"
            )}
          >
            <SectionContent title={data.title} paragraph={data.paragraph} />
          </div>
        </Row>
      </Container>
      <div
        className={cx(
          styles.desktopImage,
          data.displayType === "imageOnLeft" && styles.left
        )}
      >
        {data.imageDesktop?.data ? (
          <figure className="mb-0 d-none d-md-block">
            <img
              alt={data.imageDesktop.data.attributes.alternativeText}
              src={data.imageDesktop.data.attributes.url}
              className="img-fluid"
            />
          </figure>
        ) : (
          ``
        )}
      </div>
    </section>
  );
};

export default Websites;
