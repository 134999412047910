import React from "react";
import { Container } from "react-bootstrap";
// import Fade from 'react-reveal/Fade';
import OurCustomers from "../our-customers/OurCustomers";
import * as styles from "./Hero.module.scss";

const Hero = ({ clients, data }) => {
  return (
    <section className={`uikit-bg-dark ${styles.section}`}>
      <div className={styles.colouring}>
        {data.backgroundImageMobile?.data ? (
          <figure className="mb-0 d-sm-none">
            <img
              alt={data.backgroundImageMobile.data.attributes.alternativeText}
              src={data.backgroundImageMobile.data.attributes.url}
              className="img-fluid w-100"
            />
          </figure>
        ) : (
          ``
        )}
        {data.backgroundImageDesktop?.data ? (
          <figure className="mb-0 d-none d-sm-block">
            <img
              alt={data.backgroundImageDesktop.data.attributes.alternativeText}
              src={data.backgroundImageDesktop.data.attributes.url}
              className="img-fluid w-100"
            />
          </figure>
        ) : (
          ``
        )}
      </div>
      <Container className={styles.container}>
        {/*<Fade left duration={1000} delay={500} ssrReveal>*/}
        {/*	<h1 className={`${styles.title} uikit-h0 uikit-mobile-36`}*/}
        {/*	    dangerouslySetInnerHTML={{__html: data.title}} />*/}
        {/*</Fade>*/}
        <h1
          className={`${styles.title} uikit-h0 uikit-mobile-36`}
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
      </Container>
      <OurCustomers clients={clients} />
    </section>
  );
};

export default Hero;
