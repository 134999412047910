import React from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './Contact.module.scss';

const Contact = ({ data }) => {
	return (
		<section className={`${styles.section} uikit-bg-yellow contact-section`}>
			<div className={styles.colouring}>
				{data.backgroundImageMobile?.data ? (
					<figure className="mb-0 d-sm-none">
						<img
							alt={data.backgroundImageMobile.data.attributes.alternativeText}
							src={data.backgroundImageMobile.data.attributes.url}
							className="img-fluid"
						/>
					</figure>
				) : ``}
				{data.backgroundImageDesktop?.data ? (
					<figure className="mb-0 d-none d-sm-block">
						<img
							alt={data.backgroundImageDesktop.data.attributes.alternativeText}
							src={data.backgroundImageDesktop.data.attributes.url}
							className="img-fluid"
						/>
					</figure>
				) : ``}
			</div>
			<Container className="d-flex justify-content-center">
				<div className={styles.content}>
					<h2 className={`uikit-h1 uikit-mobile-28 ${styles.cta}`}>{data.firstTitle}</h2>
					<h2
						className={`uikit-h1 uikit-mobile-28 ${styles.cta}`}
						dangerouslySetInnerHTML={{ __html: data.secondTitle }}
					/>
				</div>
			</Container>
		</section>
	);
};

export default Contact;
