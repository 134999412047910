import React, { useState } from 'react';
import { Container, Row, Col, Tabs, Tab, Carousel } from 'react-bootstrap';
import * as styles from './Services.module.scss';

const Services = ({ data }) => {

	const [index, setIndex] = useState(0);

	const handleSlideSelect = (items, selectedIndex, e) => {
		if (selectedIndex >= items.length || selectedIndex < 0) {
			setIndex(0);
		} else if (selectedIndex !== index) {
			setIndex(selectedIndex);
		}
	}

	return (
		<section className={styles.container}>
			<Container>
				<Row>
					<Col>
						<h2 className={`uikit-h2 uikit-mobile-36`}>{data.title}</h2>
						<p className={`${styles.intro} uikit-p-16 uikit-mobile-20`}>{data.paragraph}</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<Tabs defaultActiveKey={data.services[0].service.data.attributes.slug}
						      id={`services`}>
							{data.services.map((service) => {

								const slides = service.caseStudies.data;
								let indicatorLabels = [];

								slides.forEach((slide) => {
									indicatorLabels.push(slide.attributes.title);
								});

								const serviceSlug = service.service.data.attributes.slug;
								const serviceTitle = service.service.data.attributes.title;

								return (
									<Tab tabClassName={`custom-nav-link`}
									     eventKey={serviceSlug}
									     title={serviceTitle}
									     key={serviceSlug}>
										<div className={`custom-carousel`}>
											<Carousel
												activeIndex={index}
												controls={false}
												indicators={false}
												indicatorLabels={indicatorLabels}
												interval={null}
												onSelect={handleSlideSelect}
											>
												{slides.map((slide, index) => {
													return (
														<Carousel.Item key={`Slide_${serviceSlug}_${index}`}>
															<Row>
																<Col xs={12} md={6} lg={7}>
																	{slide.attributes.featuredImage?.data ? (
																		<figure className="mb-4 mb-md-0">
																			<img
																				className="img-fluid"
																				alt={slide.attributes.featuredImage.data.attributes.alternativeText}
																				src={slide.attributes.featuredImage.data.attributes.url}
																			/>
																		</figure>
																	) : ``}
																</Col>
																<Col xs={12} md={6} lg={5}>
																	<h3 className={`uikit-h3 mb-3 mt-md-4`}>{slide.attributes.title}</h3>
																	<p>{slide.attributes.description}</p>
																</Col>
															</Row>
														</Carousel.Item>
													)
												})}
											</Carousel>
											<ol className={`carousel-indicators carousel-indicators--spaced`}>
												{slides.map((slide, slideIndex) => {
													return (
														<li
															key={`Indicator_${serviceSlug}_${slideIndex}`}
															className={((slideIndex === index) ? `active` : ``)}
														>
															<button
																className="carousel-indicator-button"
																type="button"
																aria-label="View slide"
																onClick={() => handleSlideSelect(slides, slideIndex, null)}
																onKeyDown={() => handleSlideSelect(slides, slideIndex, null)}
															>
																{slide.attributes.hero?.clientLogo?.data ? (
																	<figure className="mb-0">
																		<img
																			className="img-fluid"
																			alt={slide.attributes.hero.clientLogo.data.attributes.alternativeText}
																			src={slide.attributes.hero.clientLogo.data.attributes.url}
																			width={150}
																		/>
																	</figure>
																) : ``}
															</button>
														</li>
													)
												})}
											</ol>
										</div>
									</Tab>
								)
							})}
						</Tabs>
					</Col>
				</Row>
			</Container>
		</section>
	)
};

export default Services;
