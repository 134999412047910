import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import Fade from 'react-reveal/Fade';
import * as styles from './Intro.module.scss';

const Intro = ({ data }) => {
	return (
		<section className={styles.section}>
			<Container>
				<Row>
					<Col md={6} lg={7}>
						{/*<Fade bottom duration={1000} delay={300} distance="0px" ssrReveal>*/}
						{/*	<h2 className={`${styles.title} uikit-h0 mt-3 mt-md-4 text-uppercase`}>{data.title}</h2>*/}
						{/*	<p className="uikit-p-20">{data.paragraph}</p>*/}
						{/*</Fade>*/}
						<h2 className={`${styles.title} mt-3 mt-md-4 text-uppercase`}>{data.title}</h2>
						<p className="uikit-p-20">{data.paragraph}</p>
					</Col>
					{data.imageDesktop?.data || data.imageMobile?.data ? (
						<Col md={6} lg={5} className={`position-relative`}>
							{data.imageMobile?.data ? (
								<figure className={`${styles.image} mb-0 d-md-none`}>
									<img
										alt={data.imageMobile.data.attributes.alternativeText}
										src={data.imageMobile.data.attributes.url}
										className="img-fluid w-100"
									/>
								</figure>
							) : ``}
							{data.imageDesktop?.data ? (
								<figure className={`${styles.image} mb-0 d-none d-md-block`}>
									<img
										alt={data.imageDesktop.data.attributes.alternativeText}
										src={data.imageDesktop.data.attributes.url}
										className="img-fluid w-100"
									/>
								</figure>
							) : ``}
						</Col>
					) : ``}
				</Row>
			</Container>
		</section>
	);
};

export default Intro;
